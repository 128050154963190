body {
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir,
    helvetica neue, helvetica, ubuntu, roboto, noto, segoe ui, arial, sans-serif;
  background: #000000f6;
}

.content-container {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  background: #000000f6;
}

.back-button {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 999999999;
}

.info-container {
  width: 100%;
  position: absolute;
  top: 50px;
  z-index: 99999999;
}

* {
  scrollbar-width: auto;
  scrollbar-color: #d4d4d496 #141517;
}

*::-webkit-scrollbar {
  width: 7px;
}

*::-webkit-scrollbar-track {
  background-color: rgba(20, 21, 23, 0);
}

*::-webkit-scrollbar-thumb {
  background-color: rgba(212, 212, 212, 0.5);
  border-radius: 10px;
  border: 0px none;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: rgba(212, 212, 212, 0.7);
}
